const user_scopes = {
    descriptions: {
        custom_data: 'Özel verilerin',
        email: 'E-posta adresin',
        phone: 'Telefon numaran',
        profile: 'Adın, kullanıcı adın, profil fotoğrafın ve diğer kişisel bilgilerin',
        roles: 'Rollerin',
        identities: 'Bağlı sosyal hesapların',
        'urn:logto:scope:organizations': 'Organizasyon bilgilerin',
        'urn:logto:scope:organization_roles': 'Organizasyon rollerin',
        address: 'Adresin',
    },
};
export default Object.freeze(user_scopes);
